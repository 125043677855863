a {
  cursor: pointer;
}

li {
  list-style: none;
}

.min-h-auto {
  min-height: auto !important;
}

.mw-15rem {
  max-width: 15rem;
}

.before-seperator > *:not(:first-child) {
  margin-left: 0.4rem;
  display: flex;
  align-items: center;

  &:before {
    --tw-content: "●";
    content: "●";
    font-size: 0.75rem;
    margin-right: 0.4rem;
  }
}

.MuiDataGrid-editInputCell {
  padding: 0 0.25rem;
  width: 100%;

  > .MuiInputBase-input {
    padding: 0.25rem 0.75rem;
    width: 100%;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);
    cursor: default;
    min-height: 38px;
    outline: 0 !important;
    transition: all 100ms;
    box-sizing: border-box;
  }
}

.stretched-link {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    content: "";
  }
}

.table-condence td:not([class*="p-0"]) {
  padding: 0.3rem 1rem !important;
}
