:root {
  font-size: 14px;
}

.list-group {
  border-radius: 0 !important;
  padding: 0.5rem 0;
}

.list-group-item {
  min-width: 15rem;
  border-left: 4px solid transparent !important;
}

.list-group-item.active {
  border-color: #00000020;
  background: #fe802a1a;
  color: inherit;
  border-left-color: #fe802a !important;
  font-weight: bold;
}

.list-group-item + .list-group-item.active {
  border-top-width: 0;
}

.stepper-head-text {
  padding: 0.4rem;
  font-size: inherit !important;
}

.stepper-head-icon:empty {
  width: 0 !important;
}

.stepper-head:hover {
  background-color: transparent;
}

.stepper-head:hover .stepper-head-text {
  color: white;
  border-radius: 4px;
}

.stepper-head[disabled] {
  pointer-events: none;
}

.datatable table td {
  padding: 0.5rem 1.5rem;
  vertical-align: middle;
}

.datatable tbody tr:last-child {
  height: auto;
}

.table-fixed table {
  table-layout: fixed;
}

.form-outline {
  flex: 1 1 0%;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root {
  background-color: #fff;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%);
}

.MuiToolbar-root.MuiToolbar-regular.css-r6ewbb-MuiToolbar-root {
  min-height: 0;
  padding: 8px 0;
}

.MuiDataGrid-cell {
  position: relative;

  a::after {
    content: "";
    inset: 0;
    position: absolute;
  }
}
