/*
  NOTE: The order of imports is important.
*/

@tailwind base;

@import "./variables";
@import "./theming";

// Fetching fonts
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");

// component library imports should be done after setting variables
@import "../../node_modules/react-dropzone/examples/theme.css";
@tailwind components;

// Custom Compoent
@import "./scrollbar";

// overrides and globals should be after themes.
@import "./overrides";
@import "./globals";
@tailwind utilities;
